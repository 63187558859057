const existingMenuItems = [
  {
    title: "Anasayfa",
    route: "home",
    icon: "HomeIcon",
    action: "read",
    resource: "home",
  },
  {
    title: "Raporlar",
    icon: "BarChartIcon",
    children: [
      {
        title: "Randevu Raporları",
        route: "RandevuRaporlari",
        icon: "PieChartIcon",
        action: "read",
        resource: "RandevuRaporlari",
      },
      {
        title: "Tasarım Sayfası",
        route: "TasarimSayfasi",
        icon: "PieChartIcon",
        action: "read",
        resource: "TasarimSayfasi",
      },
    ],
  },
  {
    title: "Yönetim",
    icon: "SettingsIcon",
    children: [
      {
        title: "Görev Yönetimi",
        route: "Gorevler",
        icon: "ClockIcon",
        action: "read",
        resource: "Gorevler",
      },
      {
        title: "Sorgu Ekle",
        route: "SorguEkle",
        icon: "PlusIcon",
        action: "read",
        resource: "SorguEkle",
      },
      {
        title: "Sayfa Ekle",
        route: "SayfaEkle",
        icon: "PlusIcon",
        action: "read",
        resource: "SayfaEkle",
      },
      {
        title: "Modul Ekle",
        route: "ModulEkle",
        icon: "PlusIcon",
        action: "read",
        resource: "ModulEkle",
      },
      {
        title: "Rol Ekle",
        route: "RolEkle",
        icon: "PlusIcon",
        action: "read",
        resource: "RolEkle",
      },
      {
        title: "Rol Yetki Yonetimi",
        route: "RolYetkiYonetimi",
        icon: "SunsetIcon",
        action: "read",
        resource: "RolYetkiYonetimi",
      },
    ],
  },

  // Diğer menü öğeleri buraya eklenebilir
];

function createCombinedMenu() {
  const combinedMenuItems = [...existingMenuItems];

  return combinedMenuItems;
}

// Oluşturulan menüyü dışa aktar
export default createCombinedMenu();
